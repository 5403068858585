/* PREVIEW PAGE */

#wrapper_admin_edit{
    background: rgba(0,0,0, 0.8);
    color: #fff;
    width: 100%;
    position: fixed;
    padding: 10px;
    top:0;
    left:0;
    text-align: center;
    border-bottom: 1px solid #fff;
    z-index: 9999;
}