/* ------------------------------------------------------------
	Reset Styles (from meyerweb.com)
------------------------------------------------------------ */

/*
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, font, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	font-weight: inherit;
	font-style: inherit;
	font-size: 100%;
	font-family: inherit;
	vertical-align: baseline;
}
*/

/* ------------------------------------------------------------
	NUMBER OF COLUMNS: Adjust #primaryNav li to set the number
	of columns required in your site map. The default is 
	4 columns (25%). 5 columns would be 20%, 6 columns would 
	be 16.6%, etc. 
------------------------------------------------------------ */

/*########################################
##########################################
###########    SITEMAP     ###############
##########################################
########################################*/

 #sitemap .page-header.slider figure figcaption {
    margin: 45px 85px;
    width: 510px;
}

#sitemap .page-header.slider figure figcaption h1 {
    font-size:34px;
    line-height: 27px;
    padding-left: 10px;
}


@media (max-width: 991px){
    #sitemap .page-header.slider figure figcaption {
        position: absolute;
        margin: 10px 85px;
        padding: 35px;
        width: auto;
    }
}

@media (max-width: 767px){
    #sitemap .page-header.slider figure figcaption {
        position: relative;
        margin: 0px 70px -6px 70px;
        padding: 35px;
        width: auto;
    }
}

/*SLICKMAP*/

.sitemap #primaryNav li {
	width:25%;
}

.sitemap #primaryNav li ul li {
	width:100% !important;
}

.sitemap #primaryNav.col2 li { width:50.0%; }

.sitemap #primaryNav.col4 li { width:25.0%; }
.sitemap #primaryNav.col5 li { width:20.0%; }
.sitemap #primaryNav.col6 li { width:16.6%; }
.sitemap #primaryNav.col7 li { width:14.2%; }
.sitemap #primaryNav.col8 li { width:12.5%; }
.sitemap #primaryNav.col9 li { width:11.1%; }
.sitemap #primaryNav.col10 li { width:10.0%; }

@media (max-width: 572px ){
    .sitemap #primaryNav.col1 li { width:99.9%; }
    .sitemap #primaryNav.col3 li { width:33.3%; }
}

/* ------------------------------------------------------------
	General Styles
------------------------------------------------------------ */

.sitemap body {
	background: white;
	color: black;
	padding: 40px;
	font-family: Gotham, Helvetica, Arial, sans-serif;
	font-size: 12px;
	line-height: 1;
}
.sitemap .sitemap {
	margin: 0 0 40px 0;
	float: left;
	width: 100%;
}
.sitemap h1 {
	font-weight: bold;
	text-transform: uppercase;
	font-size: 20px;
	margin: 0 0 5px 0;
}
.sitemap h2 {
	font-family: "Lucida Grande", Verdana, sans-serif;
	font-size: 10px;
	color: #777777;
	margin: 0 0 20px 0;
}
.sitemap a {
	text-decoration: none;
}
.sitemap ol, ul {
	list-style: none;
}


/* ------------------------------------------------------------
	Site Map Styles
------------------------------------------------------------ */

/* --------	Top Level --------- */

.sitemap #primaryNav {
	margin: 0;
	float: left;
	width: 100%;
	margin-bottom: 70px;
}
.sitemap #primaryNav #home {
	display: block;
	float: none;
	background: #ffffff url('../images/L1-left.png') center bottom no-repeat;
	position: relative;
	z-index: 2;
	padding: 0 0 30px 0;
}
.sitemap #primaryNav li {
	float: left;
	background: url('../images/L1-center.png') center top no-repeat;
	padding: 30px 0;
	margin-top: -30px;
}
.sitemap #primaryNav li a {
	margin: 0 20px 0 0;
	padding: 15px 0;
	display: block;
	font-size: 14px;
	font-weight: bold;
	text-align: center;
	color: white;	
	background: rgba(185, 31, 56, 1) ;
		
}
.sitemap #primaryNav li a:hover {
	background-color: #b91f38;
    opacity: 0.8;
	
}
.sitemap #primaryNav li:last-child {
	background: url('../images/L1-right.png') center top no-repeat;
}
.sitemap a:link:before,
.sitemap a:visited:before {
	
	display: block;
	
	font-size: 10px;
	margin-bottom: 5px;
	word-wrap: break-word;
}
.sitemap #primaryNav li a:link:before,
.sitemap #primaryNav li a:visited:before {
	color: #e8e8e8;
}

/* --------	Second Level --------- */

.sitemap #primaryNav li li {
	width: 100%;
	clear: left;
	margin-top: 0;
	padding: 10px 0 0 0;
	background: url('../images/vertical-line.png') center bottom repeat-y;
}
.sitemap #primaryNav li li a {
	background-color: rgba(144, 158, 167, 1);
}
.sitemap #primaryNav li li a:hover {
	
	background-color: #909ea7;
    opacity: 0.8;
    
}
.sitemap #primaryNav li li:first-child {
	padding-top: 30px;
}
.sitemap #primaryNav li li:last-child {
	background: url('../images/vertical-line.png') center bottom repeat-y;
}
.sitemap #primaryNav li li a:link:before,
.sitemap #primaryNav li li a:visited:before {
	color: #444444;
}

/* --------	Third Level --------- */

.sitemap #primaryNav li li ul {
	margin: 10px 0 0 0;
	width: 100%;
	float: right;
	padding: 9px 0 10px 0;
	background: #ffffff url('../images/L3-ul-top.png') center top no-repeat;
}
.sitemap #primaryNav li li li {
	background: url('../images/L3-center.png') left center no-repeat;
	padding: 5px 0;
}
.sitemap #primaryNav li li li a {
	background-color: #fff7aa;
	border-color: #e3ca4b;
	font-size: 12px;
	padding: 5px 0;
	width: 80%;
	float: right;
}
.sitemap #primaryNav li li li a:hover {
	background-color: #fffce5;
	border-color: #d1b62c;
}
.sitemap #primaryNav li li li:first-child {
	padding: 15px 0 5px 0;
	background: url('../images/L3-li-top.png') left center no-repeat;
}
.sitemap #primaryNav li li li:last-child {
	background: url('../images/L3-bottom.png') left center no-repeat;
}
.sitemap #primaryNav li li li a:link:before,
.sitemap #primaryNav li li li a:visited:before {
	color: #ccae14;
	font-size: 9px;
}


/* ------------------------------------------------------------
	Utility Navigation
------------------------------------------------------------ */

.sitemap #utilityNav {
	float: right;
	max-width: 50%;
	margin-right: 10px;
}
.sitemap #utilityNav li {
	float: left;
	margin-bottom: 10px;
}
.sitemap #utilityNav li a {
	margin: 0 10px 0 0;
	padding: 5px 10px;
	display: block;	
	border: 2px solid #e3ca4b;
	font-size: 12px;
	font-weight: bold;
	text-align: center;
	color: black;
	background: #fff7aa url('../images/white-highlight.png') top left repeat-x;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	-webkit-box-shadow: rgba(0,0,0,0.5) 2px 2px 2px; 
	-moz-box-shadow: rgba(0,0,0,0.5) 2px 2px 2px; /* FF 3.5+ */	
}
.sitemap #utilityNav li a:hover {
	background-color: #fffce5;
	border-color: #d1b62c;
}
.sitemap #utilityNav li a:link:before,
.sitemap #utilityNav li a:visited:before {
	color: #ccae14;
	font-size: 9px;
	margin-bottom: 3px;
}
