/* custom stylesheet */

@charset "utf-8";

/*----*/

ol,
ul,
li {
	margin: 0;
	padding: 0;
	list-style-type: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
}
button:focus {
	outline: none;
}
a:hover,
a:visited,
a:focus,
a:active {
	text-decoration: none;
	outline: none;
}
a {
	color: #b91f38;
	-webkit-transition: all 200ms ease-in-out;
	-o-transition: all 200ms ease-in-out;
	transition: all 200ms ease-in-out;
	cursor: pointer;
}
a:hover {
	color: #0f0f0f;
}
a.pointer-none {
	pointer-events: none;
}
/*----*/

/* colors - red : #b81f32; */

/* colors - main text : #7e888e; */

/*----*/

@media (min-width: 1200px) {
	.container {
		max-width: 1400px;
	}
}
.title-article.title-red {
	color: #c4524e;
}
.title-article.title-white {
	color: #ffffff;
}

.button_cont {
	margin-top: 50px;
}
a.link {
	cursor: pointer;
	border: 2px solid #b91f38 !important;
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 700;
	color: #b91f38 !important;
	line-height: 1.2;
	position: relative;
	-webkit-transition: all 200ms ease-in-out;
	-o-transition: all 200ms ease-in-out;
	transition: all 200ms ease-in-out;
	padding: 20px 10px;
}
a.link:hover {
	color: #7e888e;
}
a.link svg {
	display: inline-block;
	position: relative;
	-webkit-transition: all 200ms ease-in-out;
	-o-transition: all 200ms ease-in-out;
	transition: all 200ms ease-in-out;
}
a.link:hover svg {
	right: -10px;
}
a.btn-custom,
button.btn-custom {
	color: #fff;
	padding: 15px 50px;
	text-transform: uppercase;
	background: #b91f38;
	border-radius: 0 !important;
	font-weight: 700;
	-webkit-transition: all 200ms ease-in-out;
	-o-transition: all 200ms ease-in-out;
	transition: all 200ms ease-in-out;
}
a.btn-custom:hover,
#contact-form button.btn-custom:hover {
	color: #fff;
	background: #0f0f0f;
}
/* GENERIC */

html,
body {
	font-family: "montserrat", "Arial", sans-serif;
	font-size: 14px;
	line-height: 1.6;
	color: #7e888e;
	background: #ffffff;
	overflow-x: hidden;
}

/* Bootstrap breadcrumb */
.breadcrumb {
	list-style: none;
	text-transform: uppercase;
	font-size: 18px;
	font-weight: 600;
	letter-spacing: 0.8px;
	background: transparent;
	padding: 70px 0 100px 0;
	border-radius: 0;
	margin-bottom: 0;
}
.breadcrumb > .active {
	color: #b91f38;
	font-weight: 700;
}
.breadcrumb li a {
	color: black;
}
.about .breadcrumb li a {
	color: #fff;
}
.breadcrumb > li + li::before,
.breadcrumb-item + .breadcrumb-item::before {
	font-family: "Font Awesome 5 Free";
	font-weight: 700;
	content: "\f054";
	margin: 0 0.5rem;
	padding: 0 0.5rem;
	color: black;
	font-size: 18px;
}
.about .breadcrumb > li + li::before,
.about .breadcrumb-item + .breadcrumb-item::before {
	color: #fff;
}
/* -- */

/* header */
#header nav {
	padding: 25px 20px 20px 20px;
}
#header.border-top-red {
	border-top: solid 4px #b81f32;
	border-bottom: 1px solid rgba(255, 255, 255, 0.2);
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1030;
	width: auto;
	background: rgba(0, 0, 0, 0.5);
}
#header .logo {
	display: inline-block;
}
#header .main-navbar {
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-end;
	margin-left: auto;
}
#header .main-menu {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	transition: all 0.35s ease;
}
#header .main-menu .nav-item {
	display: inline-block;
	list-style: outside none none;
	margin: 8px;
	padding: 0;
}
#header .main-menu .nav-item > a {
	font-size: 13px;
	font-weight: 600;
	letter-spacing: 1px;
	color: #fff;
	text-transform: uppercase;
	text-decoration: none;
	position: relative;
	padding: 0.5em 0.8em;
	-webkit-transition: all 200ms ease-in;
	-o-transition: all 200ms ease-in;
	transition: all 200ms ease-in;
}
#header .main-menu .nav-item > a:hover {
	color: #b81f32;
}
#header .main-menu .nav-item > a::before,
#header .main-menu .nav-item > a::after {
	content: "";
	height: 14px;
	width: 14px;
	position: absolute;
	transition: all 0.35s ease;
	opacity: 0;
}
#header .main-menu .nav-item > a::before {
	content: "";
	right: -10px;
	top: -10px;
	border-top: 3px solid #b81f32;
	border-right: 3px solid #b81f32;
	transform: translate(-100%, 50%);
}
#header .main-menu .nav-item > a::after {
	content: "";
	left: -10px;
	bottom: -10px;
	border-top: 0;
	border-bottom: 3px solid #b81f32;
	border-left: 3px solid #b81f32;
	transform: translate(100%, -50%);
}
#header .main-menu .nav-item:hover > a::before,
#header .main-menu .nav-item:hover > a::after {
	transform: translate(0, 0);
	opacity: 1;
}
#header .main-menu .nav-icon {
	margin: 4px 2px;
}
#header .main-menu .nav-icon a {
	position: relative;
	display: inline-flex;
	flex-flow: column nowrap;
	justify-content: center;
	align-items: center;
	height: 60px;
	width: 80px;
	color: #fff;
	font-size: 24px;
}
#header .main-menu .nav-icon a::before,
#header .main-menu .nav-icon a::after {
	content: "";
	position: absolute;
	display: block;
	width: 70px;
	height: 70px;
	background-image: url("../images/spin-inner-circle.png");
	background-size: contain;
	background-repeat: no-repeat;
	transition: transform 500ms ease-in-out;
}
#header .main-menu .nav-icon a::after {
	background-image: url("../images/spin-outer-circle.png");
	transition: transform 700ms ease-in-out;
}
#header .main-menu .nav-icon a:hover {
	color: #8b1b29;
}
#header .main-menu .nav-icon a:hover::before,
#header .main-menu .nav-icon a:hover::after {
	transform: rotate(360deg);
}
#header .main-menu .dropdown-menu {
	position: absolute;
	background-color: rgba(0, 0, 0, 0.8);
	border: 1px solid rgba(255, 255, 255, 0.1);
	box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.5);
	top: 30px;
	margin-bottom: 20px;
}
#header .main-menu .dropdown-menu a {
	color: #fff;
	text-transform: uppercase;
	font-size: 12px;
	padding: 0.75rem 1.5rem;
}
#header .main-menu .dropdown-item:focus,
#header .main-menu .dropdown-item:hover,
#header .main-menu .dropdown-item:active {
	background-color: #b81f32;
}
#header .main-menu .dropdown:hover > .dropdown-menu {
	display: block;
	-webkit-transition: all 0.2s ease-in;
	transition: all 0.2s ease-in;
}
.navbar-brand {
	display: inline-flex;
	flex-flow: column nowrap;
	align-items: center;
	color: #cecece !important;
	gap: 16px;
	text-transform: uppercase;
	font-size: 12px;
	text-align: center;
	font-weight: 500;
}
.navbar-toggle {
	border: none;
	background: transparent !important;
}
.navbar-toggle:hover {
	background: transparent !important;
}
.navbar-dark .navbar-toggler {
	color: transparent;
	border-color: transparent;
}
@media screen and (max-width: 1199px) {
	#header .main-navbar {
		display: flex;
    flex-flow: column nowrap;
		justify-content: center;
		gap: 20px;
	}
	#header .main-menu {
    display: flex;
    flex-flow: column nowrap;
		justify-content: center;
	}
	#header .main-menu-actions {
    flex-flow: row wrap
	}
	#header .lang {
		right: 0;
	}
	#header #toggleNav {
		cursor: pointer;
		display: block;
		position: absolute;
		top: 56px;
		right: 25px;
	}
	#header #toggleNav span {
		width: 50px;
		height: 2px;
		display: block;
		background: #b81f32;
		margin-top: 8px;
	}
}

/* Hamburger icon */
.animated-icon1,
.animated-icon2,
.animated-icon3 {
	width: 30px;
	height: 20px;
	position: relative;
	margin: 0;
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: 0.5s ease-in-out;
	-moz-transition: 0.5s ease-in-out;
	-o-transition: 0.5s ease-in-out;
	transition: 0.5s ease-in-out;
	cursor: pointer;
}
.animated-icon1 span,
.animated-icon2 span,
.animated-icon3 span {
	display: block;
	position: absolute;
	height: 3px;
	width: 100%;
	border-radius: 9px;
	opacity: 1;
	left: 0;
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: 0.25s ease-in-out;
	-moz-transition: 0.25s ease-in-out;
	-o-transition: 0.25s ease-in-out;
	transition: 0.25s ease-in-out;
}
.animated-icon1 span {
	background: #fff;
}
.animated-icon1 span:nth-child(1) {
	top: 0;
}
.animated-icon1 span:nth-child(2) {
	top: 10px;
}
.animated-icon1 span:nth-child(3) {
	top: 20px;
}
.animated-icon1.open span:nth-child(1) {
	top: 11px;
	-webkit-transform: rotate(135deg);
	-moz-transform: rotate(135deg);
	-o-transform: rotate(135deg);
	transform: rotate(135deg);
}
.animated-icon1.open span:nth-child(2) {
	opacity: 0;
	left: -60px;
}
.animated-icon1.open span:nth-child(3) {
	top: 11px;
	-webkit-transform: rotate(-135deg);
	-moz-transform: rotate(-135deg);
	-o-transform: rotate(-135deg);
	transform: rotate(-135deg);
}
/* -- */

button.first-button:focus {
	outline: none;
}

/* hero */

.page-header {
	position: relative;
}
.page-header h1 {
	font-weight: 300;
	letter-spacing: 1.1px;
	font-size: 2.5vw;
}
.hero {
	position: relative;
	height: 100vh;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	z-index: 2;
}
.hero-content {
	position: absolute;
	text-align: left;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: #fff;
}
.hero-title {
	font-size: 5rem;
	font-weight: 300;
	margin-bottom: 0;
	text-transform: uppercase;
}
.hero-title span {
	font-size: 2rem;
}
@media screen and (max-width: 767px) {
	.hero-title {
		font-size: 8vw;
		padding-top: 1.5rem !important;
	}
	.hero-title span {
		font-size: 6vw;
	}
}
/* -- */

.title-article {
	text-align: center;
	line-height: 1.1em;
	margin-bottom: 100px;
	font-weight: 700;
	font-size: 24px;
	color: #b91f38;
	text-transform: uppercase;
}
#home-craft {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	background: rgba(0, 0, 0, 0.9);
	border-top: solid 10px #b81f32;
	z-index: 3;
}
@media (max-width: 1200px) {
	#home-craft.container {
		width: 100%;
		max-width: 100%;
	}
}
@media screen and (max-width: 768px) {
	#home-craft {
		position: relative;
	}
}
#home-craft > div div {
	position: relative;
	padding: 2rem 2rem 1rem 2rem;
}
/* HOMEPAGE FIGS*/

.fig .frame {
	display: block;
	position: relative;
	width: 100%;
	overflow: hidden;
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	-ms-backface-visibility: hidden;
	backface-visibility: hidden;
}
.fig .frame {
	width: 100%;
}
.fig.fig-home .frame img {
	height: auto;
	position: absolute;
	top: 0;
	left: 50%;
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	-o-transform: translateX(-50%);
	transform: translateX(-50%);
	-webkit-transition: transform 200ms ease-in-out;
	-o-transition: transform 200ms ease-in-out;
	transition: transform 200ms ease-in-out;
}
.fig.fig-home .frame:hover img {
	-webkit-transform: translateX(-50%) scale(1.05);
	-ms-transform: translateX(-50%) scale(1.05);
	-o-transform: translateX(-50%) scale(1.05);
	transform: translateX(-50%) scale(1.05);
}
.fig {
	position: relative;
	margin: 1rem 0 1rem;
}
.fig.fig-home .frame {
	height: 180px;
}
.fig.fig-home .title {
	position: relative;
	text-align: center;
	margin-top: 15px;
	left: 0;
	right: 0;
	bottom: 0;
	display: block;
	height: 0;
	padding: 0;
	background: none;
	font-size: 12px;
	font-weight: 700;
	color: #ffffff;
	letter-spacing: 0.1em;
	line-height: 1.4em;
	text-transform: uppercase;
	-webkit-transition: background 200ms ease-in-out;
	-o-transition: background 200ms ease-in-out;
	transition: background 200ms ease-in-out;
}
.fig.fig-home a:hover .title {
	background: rgba(182, 32, 49, 1);
}
.fig.fig-home figcaption {
	padding: 0 25px;
	margin: 20px 0;
}
.fig.fig-news .frame img {
	height: 100%;
	max-width: unset;
	position: absolute;
	top: 0;
	left: 50%;
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	-o-transform: translateX(-50%);
	transform: translateX(-50%);
	-webkit-transition: transform 200ms ease-in-out;
	-o-transition: transform 200ms ease-in-out;
	transition: transform 200ms ease-in-out;
}
.fig.fig-news .frame:hover img {
	-webkit-transform: translateX(-50%) scale(1.05);
	-ms-transform: translateX(-50%) scale(1.05);
	-o-transform: translateX(-50%) scale(1.05);
	transform: translateX(-50%) scale(1.05);
}
.fig.fig-news .frame {
	float: left;
	width: 150px;
	height: 100px;
	display: block;
}
.fig.fig-news .frame .title {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	display: block;
	height: 55px;
	padding: 0 15px 0 25px;
	background: rgba(182, 32, 49, 0.8);
	font-size: 16px;
	font-weight: 700;
	color: #ffffff;
	line-height: 55px;
	text-transform: uppercase;
	-webkit-transition: background 200ms ease-in-out;
	-o-transition: background 200ms ease-in-out;
	transition: background 200ms ease-in-out;
}
.fig.fig-news figcaption {
	float: left;
	display: block;
	width: calc(100% - 150px);
	padding-left: 20px;
}
/* hp menu */

#home-craft nav {
	color: #fff;
	background: #3b3b3b;
}
#home-craft nav a {
	color: #fff;
	font-size: 15px;
	font-weight: 700;
	letter-spacing: 0.1em;
	line-height: 1.4em;
	text-transform: uppercase;
	-webkit-transition: background 200ms ease-in-out;
	-o-transition: background 200ms ease-in-out;
	transition: background 200ms ease-in-out;
	padding: 15px;
}
#home-craft nav a#nav-activities-tab {
	border-right: 1px solid #5c0f19;
}
#home-craft nav a#nav-jobs-tab {
	border-left: 1px solid #5c0f19;
}
#home-craft nav a[aria-expanded="true"],
#home-craft nav a:hover {
	color: #fff;
	background: #b81f32;
}

/* @media screen and (max-width : 550px) {
  #home-craft .nav-justified .nav-item {
    flex-basis: auto;
  }
}  */

/* caret */
#nav-activities-tab::after,
#nav-jobs-tab::after {
	font-family: "Font Awesome 5 Free";
	font-weight: bold;
	margin-left: 5px;
	color: #fff;
	font-size: 16px;
}
#nav-activities-tab::after,
#nav-jobs-tab::after {
	content: "\f0d8";
}
#nav-jobs-tab.collapsed::after,
#nav-activities-tab.collapsed::after {
	content: "\f0d7";
}

/* -- */

/* video */

.video-background-holder {
	position: relative;
	height: 100vh;
	min-height: 25rem;
	width: 100%;
	overflow: hidden;
}
.video-background-holder video {
	position: absolute;
	top: 50%;
	left: 50%;
	min-width: 100%;
	min-height: 100%;
	width: auto;
	height: auto;
	z-index: 0;
	-ms-transform: translateX(-50%) translateY(-50%);
	-moz-transform: translateX(-50%) translateY(-50%);
	-webkit-transform: translateX(-50%) translateY(-50%);
	transform: translateX(-50%) translateY(-50%);
}
.video-background-content {
	position: relative;
	z-index: 2;
}
.video-background-content.container {
	width: 1400px;
	max-width: 1400px;
}
.video-background-overlay {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background-color: black;
	opacity: 0.5;
	z-index: 1;
}
@media (min-aspect-ratio: 16/9) {
	#videoBG {
		width: 100%;
		height: auto;
	}
}
@media (max-aspect-ratio: 16/9) {
	#videoBG {
		width: 100%;
		height: auto;
	}
}
/* -- */

/* hp content */

#about {
	background: #0f0f0f;
}
#about h2 {
	margin: 35px 0;
	text-align: left;
}
#about article {
	-webkit-background-size: contain;
	background-size: cover;
	background: #0f0f0f;
}
#about article p {
	color: #fff;
	line-height: 1.8em;
	font-weight: 400;
}
#about article p span {
	color: #b81f32;
	line-height: 1.8em;
	font-weight: 400;
}
.inner-title {
	text-transform: uppercase;
	color: #b91f38;
	font-weight: 700;
	font-size: 30px;
}
.inner-title span {
	font-size: 28px;
	font-weight: 400;
}

/* -- */

#before-footer {
	padding: 125px 0 100px 0;
	background: #f2f2f2;
}
#before-footer .card-title {
	font-weight: 700;
	font-size: 18px;
	color: #b91f38;
	text-transform: uppercase;
}
#before-footer .card {
	border: 5px solid #e5e5e5;
	border-radius: 0;
}
#before-footer .card-body {
	background: #e5e5e5;
	padding: 2.5rem 1.25rem;
	transition: all 0.5s ease-out;
}
#before-footer a:hover .card-body {
	background: #b91f38;
	padding: 2.5rem 1.25rem;
}
#before-footer a:hover .card-title,
#before-footer a:hover .card-text {
	color: #fff;
}
#before-footer .card-text {
	font-size: 16px;
	color: #333333;
}

.special-hover {
	border: none;
	text-transform: uppercase;
	transition: all 0.4s;
}
.special-hover span {
	cursor: pointer;
	display: inline-block;
	position: relative;
	transition: 0.4s;
}
.special-hover span:after {
	font-family: "Font Awesome 5 Free";
	content: "\f061";
	position: absolute;
	opacity: 0;
	top: 0;
	right: -20px;
	transition: 0.5s;
}
.special-hover:hover span {
	padding-right: 25px;
}
.special-hover:hover span:after {
	opacity: 1;
	right: 0;
}

svg#logo-lorweb {
	fill: #15212e;
	transition: all 0.2s ease-in-out;
}
svg#logo-lorweb:hover {
	fill: #b91f38;
	transition: all 0.2s ease-in-out;
}
/* -- */

#partners {
	padding: 125px 0 180px 0;
	position: relative;
}

@media screen and (max-width: 600px) {
	#home-craft nav a {
		font-size: 12px;
		font-weight: 600;
	}
	#partners {
		padding: 80px 0 80px 0;
	}
}
.owl-carousel .owl-stage {
	display: flex;
	align-items: center;
}
.owl-nav {
	position: absolute;
	top: 10%;
	left: 0;
	right: 0;
}
.owl-prev,
.owl-next {
	position: absolute;
	height: 100px;
	color: inherit;
	background: none;
	border: none;
	z-index: 100;
}
.owl-nav span {
	font-size: 2.5rem;
	font-weight: 600;
	color: #b91f38;
}
.owl-prev {
	left: -60px;
}
.owl-next {
	right: -60px;
}
/* -- */

/* footer */

footer {
	padding: 60px 0 90px 0;
}
footer .container {
	padding-top: 60px;
	border-top: 5px solid #f3f3f3;
}
footer .footer-title {
	color: #b81f32;
	letter-spacing: 0.4px;
}
footer p {
	font-size: 14px;
	color: #333333;
	font-weight: 400;
}
.list-inline {
	display: inline-block !important;
}
.seperator {
	display: block;
	width: 100%;
	border-top: 1px solid #778589;
	border-bottom: 1px solid #bac5cc;
	margin: 45px 0 20px 0;
}
footer a.link {
	color: #b91f38;
	display: block;
	line-height: 1.2;
	margin: 0;
	position: relative;
	-webkit-transition: all 200ms ease-in-out;
	-o-transition: all 200ms ease-in-out;
	transition: all 200ms ease-in-out;
	font-size: 14px;
	line-height: 22px;
	font-weight: 500;
}
footer label,
footer a {
	display: inline-block;
	margin-bottom: 0;
}
footer .footer-links a {
	color: #b91f38;
	font-weight: 600;
	font-size: 16px;
	letter-spacing: 0.4px;
}
footer .footer-links a:hover {
	color: #0f0f0f;
}
/*#############################################
############               ####################
############    METIERS    ####################
############               ####################
#############################################*/

#jobs .no-video.hero {
	background-image: url("../images/blog-header.jpg");
}
#jobs.tournage-cn .no-video.hero {
	background-image: url("../images/tournage-cn-bg.jpg");
}
#jobs.electroerosion .no-video.hero {
	background-image: url("../images/electroerosion-bg.jpg");
}
#jobs.rectification .no-video.hero {
	background-image: url("../images/rectif-bg.jpg");
}

#jobs .hero-title {
	text-shadow: 3px 0 10px #000;
}
#jobs .about {
	background: #000;
	padding: 70px 0 70px 0;
	background-image: url("../images/exergue-decoration.png");
	background-repeat: no-repeat;
	background-position: right center;
	background-size: auto;
	width: 100%;
}
#jobs .about .breadcrumb {
	padding: 0 0 50px 0;
}
#jobs .about .exergue {
	text-transform: uppercase;
	color: #b91f38;
	font-size: 42px;
	font-weight: 300;
	line-height: 1.2em;
}
#jobs .about .exergue li {
	position: relative;
	list-style: none;
	padding: 25px 0;
	color: #fff;
	font-size: 20px;
	font-weight: 600;
	text-transform: initial;
	line-height: 1.6em;
	padding-left: 90px;
}

.exergue li span {
	color: #b91f38;
}
#jobs .about .exergue li::before {
	position: absolute;
	top: 50%;
	left: 0;
	transform: translate(0, -50%);
	background: url("../images/gear-icon.png");
	display: block;
	content: "";
	height: 37px;
	width: 37px;
}
#jobs .machines {
	padding: 70px 0 30px 0;
}
#jobs .machines .card-deck.nav {
	padding-top: 70px;
}
#jobs .machines {
	margin-bottom: 100px;
}
#machines-tab .card {
	border: none;
	border-radius: 0;
	height: auto;
	max-height: 340px;
	overflow: hidden;
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	-ms-backface-visibility: hidden;
	backface-visibility: hidden;
}
#machines-tab .card-body {
	position: absolute;
	width: 100%;
	bottom: 0;
	background: transparent;
	transition: all 0.2s ease-in-out;
}
#machines-tab .active .card-body {
	background: rgba(185, 31, 56, 0.8);
}
#machines-tab .card-title {
	color: black;
	font-weight: 700;
	font-size: 22px;
	text-shadow: 3px 0 15px #fff;
}
#machines-tab .active .card-title {
	color: #fff;
	font-weight: 700;
}
.machines .card-img-top {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	transition: all 0.2s ease-in-out;
}
.machines .card-img-top:hover {
	transform: scale(1.1);
}
#jobs .machine {
	border: 1px solid #dfdfdf;
	margin-top: 100px;
	margin-bottom: 40px;
	margin-right: 0;
	margin-left: 0;
}
#jobs .machine-detail {
	color: black;
}
#jobs .machine-detail ul {
	margin-top: 30px;
	padding-left: 0;
	padding-bottom: 25px;
	border-bottom: 1px solid #f0f0f0;
	font-size: 18px;
	font-weight: 400;
	text-transform: initial;
}
#jobs .machine-detail li::before {
	display: inline-flex;
	content: "";
	position: relative;
	left: 0;
	top: 0;
	width: 20px;
	height: 15px;
	z-index: 1;
	background-image: url("../images/list-style-arrow.png");
	background-repeat: no-repeat;
}
#jobs .machine h2 {
	text-transform: uppercase;
	font-size: 32px;
	line-height: 1.2em;
	font-weight: 700;
}
#jobs .machine .machine-detail h3 {
	text-transform: uppercase;
	font-size: 11px;
	color: #bfbfbf;
	line-height: 24px;
	font-weight: 600;
	padding: 15px 0;
}
#jobs .machine-detail span {
	text-transform: none;
	font-size: 14px;
	color: #bfbfbf;
	line-height: 24px;
	font-weight: 600;
	padding: 15px 0;
}
#jobs .machine-detail p {
	font-size: 14px;
	text-transform: initial;
	font-weight: 400;
}
@media screen and (max-width: 991px) {
	#machines-tab .card-title {
		font-size: 16px;
	}
}
@media screen and (max-width: 767px) {
	#jobs .about .exergue {
		font-size: 6vw;
	}
	#jobs .about {
		padding: 70px 0 70px 0;
	}
	#jobs .machines {
		padding: 20px 0 30px 0;
		margin-bottom: 0;
	}
	#jobs .machine {
		margin-top: 4rem;
	}
}
@media screen and (max-width: 480px) {
	#jobs .about .exergue li {
		padding-left: 0;
		font-size: 16px;
	}
	#jobs .about .exergue li::before {
		display: none;
	}
	#jobs .machine-detail ul {
		font-size: 14px;
	}
}
/*#############################################
############               ####################
############    BLOG    ####################
############               ####################
#############################################*/

#blog .hero {
	background-image: url("../images/blog-header.jpg");
}
#blog .blog-cards {
	margin-bottom: 100px;
}
#blog .card-body {
	background: #b91f38;
	text-transform: uppercase;
	transition: all 0.5s ease-in-out;
	color: #fff;
}
#blog .card-deck a {
	color: #fff;
}
#blog .card {
	border: 3px solid #f0f0f0;
}
#blog .card-title {
	font-size: 20px;
	font-weight: 700 !important;
}
#blog .card-text {
	font-size: 18px;
	font-weight: 400 !important;
	text-transform: initial;
}
#blog .card-date {
	font-size: 16px;
	font-weight: 700 !important;
}
#blog a:hover .card-body {
	background: #eee;
	transition: all 0.5s ease-in-out;
}
#blog a:hover .card-title,
#blog a:hover .card-text,
#blog a:hover .card-date {
	color: #b91f38;
}

#blog .page-link {
	padding: 1.5rem 1.75rem;
	color: #b91f38;
	font-weight: 700;
}
#blog .page-link:focus {
	box-shadow: none;
}
#blog .pagination {
	border-radius: 0;
}
#blog .page-item:first-child .page-link {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}
#blog .page-item:last-child .page-link {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}
/*#############################################
############               ####################
############ DOMAINES      ####################
############ D'ACTIVITES   ####################
############               ####################
###############################################
#############################################*/

#activity.cars .no-video.hero {
	background-image: url("../images/car-bg.jpg");
}
#activity.secteurs .no-video.hero {
	background-image: url("../images/autres-secteurs-bg-2.jpg");
}
#activity .hero-title {
	text-shadow: 3px 0 10px #000;
}
#activity .about {
	background: #0f0f0f;
	padding: 70px 0 70px 0;
}
#activity .about .breadcrumb {
	padding: 0 0 50px 0;
}
#activity .about .exergue {
	text-transform: uppercase;
	color: #b91f38;
	font-size: 42px;
	font-weight: 300;
	line-height: 1.2em;
}
#activity .about .exergue li {
	position: relative;
	list-style: none;
	padding: 25px 0;
	color: #fff;
	font-size: 20px;
	font-weight: 600;
	text-transform: initial;
	line-height: 1.6em;
	padding-left: 90px;
}
#activity .about .exergue li::before {
	position: absolute;
	top: 50%;
	left: 0;
	transform: translate(0, -50%);
	background: url("../images/gear-icon.png");
	display: block;
	content: "";
	height: 37px;
	width: 37px;
}
#activity .gears {
	padding: 70px 0 30px 0;
}
/* #activity .gears .container { width: 1100px; max-width: 1100px; } */
#activity .gears nav {
	padding-top: 70px;
}
#activity .gears nav > ul > li {
	margin-bottom: 50px;
}
.gears .fig.fig-home .frame {
	height: auto;
	border: 1px solid #eee;
}
.gears .fig.fig-home .frame img {
	display: block;
	position: relative;
	width: 100%;
}
.gears .fig.fig-home .frame .title {
	line-height: 1.6em;
	height: auto;
	padding: 20px;
	position: relative;
	background: #b91f38;
	text-align: left;
}
.gears .fig.fig-home .frame .title h3 {
	font-size: 20px;
	font-weight: 700;
}
.gears .fig.fig-home .frame .title span {
	font-size: 12px;
	font-weight: 400;
}
.gear-detail {
	color: black;
}
.gear-detail ul {
	padding-left: 40px;
}
.gear-detail li {
	line-height: 24px;
	font-size: 15px;
	font-size: 15px;
	text-transform: none;
	font-weight: 600;
}
.gear-detail li::before {
	position: absolute;
	font-family: "Font Awesome 5 Free";
	content: "\f054";
	color: #b91f38;
	display: block;
	font-size: 15px;
	left: 35px;
}
.gear-detail h4 {
	text-transform: uppercase;
	padding: 10px 30px 15px 30px;
	margin-bottom: 25px;
	font-size: 13px;
	color: #aaaaaa;
	line-height: 24px;
	font-weight: 700;
	border-bottom: 1px solid #f0f0f0;
}
.gear-detail span {
	color: #b91f38;
	font-size: 14px;
	font-weight: 700;
}
.gear-detail span a {
	text-decoration: underline;
	text-underline-position: under;
}
@media screen and (max-width: 767px) {
	#activity .about .exergue {
		font-size: 6vw;
	}
	#activity .about {
		padding: 70px 0 70px 0;
	}
	#activity .gears {
		padding: 20px 0 30px 0;
	}
}
@media screen and (max-width: 480px) {
	#activity .about .exergue li {
		padding-left: 0;
		font-size: 16px;
	}
	#activity .about .exergue li::before {
		display: none;
	}
}
/*#############################################
############               ####################
############   ABOUT US    ####################
############               ####################
#############################################*/
#company {
	background: url("../images/abstract-gears-bg.png") no-repeat fixed center;
	background-size: cover;
	padding-bottom: 30px;
}

#company .hero {
	background-image: url("../images/blog-header.jpg");
}
#company .about {
	background: #0f0f0f;
	padding: 70px 0 70px 0;
}
#company .about .breadcrumb {
	padding: 0 0 50px 0;
}
#company .about .exergue {
	text-transform: uppercase;
	color: #b91f38;
	font-size: 42px;
	font-weight: 300;
	line-height: 1.2em;
}
#company .about .exergue li {
	position: relative;
	list-style: none;
	padding: 25px 0;
	color: #fff;
	font-size: 20px;
	font-weight: 600;
	text-transform: initial;
	line-height: 1.6em;
	padding-left: 90px;
}
#company .about .exergue li::before {
	position: absolute;
	top: 50%;
	left: 0;
	transform: translate(0, -50%);
	background: url("../images/gear-icon.png");
	display: block;
	content: "";
	height: 37px;
	width: 37px;
}
@media screen and (max-width: 767px) {
	#company .about .exergue {
		font-size: 6vw;
	}
	#company .about {
		padding: 70px 0 70px 0;
	}
}
@media screen and (max-width: 480px) {
	#company .about .exergue li {
		padding-left: 0;
		font-size: 16px;
	}
	#company .about .exergue li::before {
		display: none;
	}
}

#company .company.container {
	max-width: 1100px;
}

.about-detail li::before {
	display: block;
	content: "";
	position: relative;
	left: -10px;
	top: 15px;
	width: 20px;
	height: 8px;
	z-index: 1;
	background-image: url("../images/list-style-arrow.png");
	background-repeat: no-repeat;
}
.about div.row:last-child .about-detail ul.row li::before {
	display: none;
}
.about-detail span {
	color: #b91f38;
	font-weight: 700;
}

#company .about-us .thumbnail {
	flex-direction: unset;
	height: auto;
}
#company .about-us ul.row {
	padding: 0;
}
#company #partners {
	padding: 0 0 70px 0;
	position: relative;
}

.company h2 {
	font-size: 23px;
	color: #b91f38;
	padding: 15px 0;
}

#company .inner-title {
	margin: 70px 0;
}
.company .title-article {
	margin-bottom: 30px;
}
/* .about-us h3 {
	font-size: 24px;
	color: #333333;
	padding: 10px 0;
} */
.about-us h4 {
	padding: 10px 0;
}
.about-us .about-detail {
	min-height: 450px;
}
.about-detail {
	background-color: rgba(255, 255, 255, 0.65);
	padding: 25px 30px;
	color: black;
	font-size: 14px;
	line-height: 24px;
	box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
	margin-bottom: 30px;
}
@media screen and (min-width: 1200px) {
	/* .about-detail { height: 400px; } */
	.about-detail .product-img {
		height: auto;
	}
}
.about-height-auto {
	height: auto;
}
.about-us .link-block {
	display: block;
	margin: 25px;
	padding: 25px 20px;
	text-align: center;
	text-transform: uppercase;
	background: #ba2037;
	transition: all 0.1s ease-in-out;
}
.about-us .link-block .f {
	display: block;
	margin: -25px -20px 20px -20px;
}
.about-us .link-block .f.fw {
	position: relative;
	overflow: hidden;
}
.about-us .link-block .f.fw::before {
	content: "";
	padding-top: 22%;
	display: block;
}
.about-us .link-block .f.fw img {
	position: absolute;
	top: 50%;
	left: 0;
	right: 0;
	transform: translateY(-50%);
	width: 100%;
}
.about-us .link-block span,
.about-us .link-block .fa {
	margin-right: 10px;
	color: #ffffff;
}
.about-us .link-block:hover {
	background: #ffffff;
}
.about-us .link-block:hover span,
.about-us .link-block:hover .fa {
	color: #ba2037;
	margin-right: 15px;
}
.about-detail li::before {
	display: block;
	content: "";
	position: relative;
	left: -10px;
	top: 15px;
	width: 20px;
	height: 8px;
	z-index: 1;
	background-image: url(../images/list-style-arrow.png);
	background-repeat: no-repeat;
}
.about-us div.row:last-child .about-detail ul.row li::before {
	display: none;
}
.thumbnail {
	padding-top: 15px;
	margin-top: 0;
	margin-bottom: 0;
	background-color: transparent;
	background-position: center center;
	background-size: contain;
	border: none;
	display: flex;
	justify-content: center;
	flex-direction: column;
	height: 100px;
}
.about-us .thumbnail {
	margin-top: 0;
	margin-bottom: 0;
	background-color: transparent;
	background-position: center center;
	background-size: contain;
	border: none;
	display: flex;
	justify-content: center;
	flex-direction: column;
	height: 70px;
}
@media (min-width: 1199px) {
	.arrow-tl > div::before {
		display: block;
		content: "";
		position: absolute;
		left: 460px;
		top: -100px;
		width: 160px;
		height: 70px;
		z-index: 1;
		background-image: url("../images/arow-u.png");
		background-repeat: no-repeat;
	}
	.arrow-tr > div::before {
		display: block;
		content: "";
		position: absolute;
		left: 570px;
		top: 385px;
		width: 160px;
		height: 155px;
		z-index: 1;
		background-image: url("../images/arrow-r.png");
		background-repeat: no-repeat;
	}
	.arrow-bl > div::before {
		display: block;
		content: "";
		position: absolute;
		left: -85px;
		top: -90px;
		width: 160px;
		height: 160px;
		z-index: 1;
		background-image: url("../images/arrow-l.png");
		background-repeat: no-repeat;
	}
	.arrow-br > div::before {
		display: block;
		content: "";
		position: absolute;
		left: -70px;
		top: 675px;
		width: 160px;
		height: 70px;
		z-index: 1;
		background-image: url("../images/arrow-d.png");
		background-repeat: no-repeat;
	}
}
@media (max-width: 767px) {
	.about-us {
		margin-bottom: 60px;
	}
}
/*########################################
##########################################
###########    CONTACT      ##############
##########################################
########################################*/

#contact .hero-content {
	position: relative;
	text-align: left;
	top: 0;
	left: 0;
	transform: initial;
	color: #b81f32;
}
@media screen and (min-width: 992px) {
	#contact-form {
		margin: 0 170px;
	}
}
/* form */

#contact-form form input.form-control {
	height: 1.5em !important;
}
#contact-form form .form-body {
	border: 6px solid #8b1b29;
	border-radius: 0.25rem;
	padding: 2.8rem 1.5rem;
	margin: 30px 0;
}
#contact-form form .form-body label {
	text-transform: uppercase;
	color: #8b1b29;
	font-size: 1.8em;
	font-weight: 700;
}
#contact-form form .form-body input {
	width: 75%;
	padding: 5px 20px;
	margin: 0;
	box-sizing: border-box;
	border: none;
	border-bottom: 2px solid #ecdadc;
	border-radius: 0;
}
#contact-form form .form-body textarea {
	border: none;
	height: 10em;
}
#contact-form .btn {
	padding: 0.75rem 1.25rem;
	margin-bottom: 0;
	background-color: #b91f38;
	border: none;
	border-radius: 0;
	font-weight: 700;
	text-transform: uppercase;
	font-size: 1.2rem;
	color: #fff;
	width: 100%;
}
label[for="policyCheck"] {
	text-transform: uppercase;
	font-weight: 700;
}

/* Creating a custom checkbox */
.check {
	width: 30px;
	height: 30px;
	position: relative;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin-right: 10px;
}
.check input {
	opacity: 0;
}
.check input:checked + .box {
	background-color: #f9dee2;
}
.check input:checked + .box:after {
	top: 0;
}
.check .box {
	width: 25px;
	height: 25px;
	transition: all 1.1s cubic-bezier(0.19, 1, 0.22, 1);
	border: 1px solid rgba(0, 0, 0, 0.2);
	background-color: #f9dee2;
	position: relative;
	overflow: hidden;
	cursor: pointer;
	box-shadow: 0 3px rgba(0, 0, 0, 0.2);
}
.check .box:after {
	width: 40%;
	height: 20%;
	content: "";
	position: absolute;
	border-left: 2.5px solid;
	border-bottom: 2.5px solid;
	border-color: #b91f38;
	transform: rotate(-45deg) translate3d(0, 0, 0);
	transform-origin: center center;
	transition: all 1.1s cubic-bezier(0.19, 1, 0.22, 1);
	left: 0;
	right: 0;
	top: 200%;
	bottom: 5%;
	margin: auto;
}

@media (min-width: 768px) and (max-width: 1199px) {
	.check {
		width: 50px;
	}
}

/* accordion */

#contact-form .card-body {
	padding: 0;
}
#contact-form .card-header {
	padding: 0.75rem 1.25rem;
	margin-bottom: 0;
	background-color: #b91f38;
	border-bottom: none;
}
#contact-form .card {
	border: none;
	border-radius: 0;
}
#contact-form .btn-link {
	font-weight: 700;
	color: #fff;
	text-transform: uppercase;
	text-decoration: none;
	font-size: 22px;
	transition: 0.4s;
	text-align: left;
	outline: none;
	cursor: pointer;
	width: 100%;
}
#contact-form .card-header .btn-link:after {
	font-family: "Font Awesome 5 Free";
	content: "\f077";
	color: #777;
	font-weight: bold;
	float: right;
	margin-left: 5px;
	color: #fff;
	font-size: 22px;
}
#contact-form .card-header .collapsed:after {
	font-family: "Font Awesome 5 Free";
	content: "\f078";
	color: #777;
	font-weight: bold;
	float: right;
	margin-left: 5px;
	color: #fff;
	font-size: 22px;
}

/* modal */
.modal {
	z-index: 1060;
	color: #0f0f0f;
}
.modal-header .title {
	color: #b91f38;
	font-weight: 600;
}
.modal-content {
	border-radius: 0;
}
.modal-content title {
	font-weight: 600;
}
.modal-content a {
	font-weight: 600;
}

@media screen and (max-width: 480px) {
	.custom-form-check-label {
		margin-top: -5px;
	}
	#contact-form .card-header .collapsed:after,
	#contact-form .btn-link,
	#contact-form .btn,
	#contact-form .card-header .btn-link:after {
		font-size: 14px;
	}
	label[for="policyCheck"] {
		font-size: 12px;
	}
}

/*#############################################
###############################################
#######              CHART          ###########
###############################################
###############################################*/

.chart-block {
	margin: 30px 0;
	height: auto;
	width: 100%;
}

@media screen and (min-width: 481px) {
	.desktop-chart {
		display: block;
	}
	.mobile-chart {
		display: none;
	}
}

@media screen and (max-width: 480px) {
	.desktop-chart {
		display: none;
	}
	.mobile-chart {
		display: block;
	}
}

dl {
	display: flex;
	background-color: #fff;
	flex-direction: column;
	width: 100%;
	max-width: 700px;
	position: relative;
	padding: 20px;
}
dt {
	align-self: flex-start;
	width: 100%;
	font-weight: 700;
	display: block;
	text-align: center;
	font-size: 1.2em;
	font-weight: 700;
	margin-bottom: 20px;
}
.mobile-chart .text {
	font-weight: 600;
	display: flex;
	align-items: center;
	height: 40px;
	width: 130px;
	background-color: #fff;
	position: absolute;
	left: 0;
	justify-content: flex-start;
}
.percentage {
	font-size: 0.8em;
	line-height: 1;
	text-transform: uppercase;
	width: 65%;
	height: 35px;
	margin-left: 140px;
}
.percentage:after {
	content: "";
	display: block;
	width: 50px;
	margin-bottom: 10px;
	height: 90%;
	position: relative;
	top: 50%;
	transform: translateY(-50%);
	transition: background-color 0.3s ease;
	cursor: pointer;
}
.percentage .text span {
	font-size: 14px;
}

.percentage:nth-child(2):after {
	background-color: rgba(255, 99, 132, 1);
}
.percentage:nth-child(3):after {
	background-color: rgba(54, 162, 235, 1);
}
.percentage:nth-child(4):after {
	background-color: rgba(255, 206, 86, 1);
}
.percentage:nth-child(5):after {
	background-color: rgba(75, 192, 192, 1);
}
.percentage:nth-child(6):after {
	background-color: rgba(153, 102, 255, 1);
}
.percentage:nth-child(7):after {
	background-color: rgba(255, 159, 64, 1);
}
.percentage:last-child:after {
	background-color: rgba(0, 0, 0, 0.1);
}

.percentage:hover:after,
.percentage:focus:after {
	background-color: #aaa;
}
.percentage-1:after {
	width: 1%;
}
.percentage-2:after {
	width: 2%;
}
.percentage-3:after {
	width: 3%;
}
.percentage-4:after {
	width: 4%;
}
.percentage-5:after {
	width: 5%;
}
.percentage-6:after {
	width: 6%;
}
.percentage-7:after {
	width: 7%;
}
.percentage-8:after {
	width: 8%;
}
.percentage-9:after {
	width: 9%;
}
.percentage-10:after {
	width: 10%;
}
.percentage-11:after {
	width: 11%;
}
.percentage-12:after {
	width: 12%;
}
.percentage-13:after {
	width: 13%;
}
.percentage-14:after {
	width: 14%;
}
.percentage-15:after {
	width: 15%;
}
.percentage-16:after {
	width: 16%;
}
.percentage-17:after {
	width: 17%;
}
.percentage-18:after {
	width: 18%;
}
.percentage-19:after {
	width: 19%;
}
.percentage-20:after {
	width: 20%;
}
.percentage-21:after {
	width: 21%;
}
.percentage-22:after {
	width: 22%;
}
.percentage-23:after {
	width: 23%;
}
.percentage-24:after {
	width: 24%;
}
.percentage-25:after {
	width: 25%;
}
.percentage-26:after {
	width: 26%;
}
.percentage-27:after {
	width: 27%;
}
.percentage-28:after {
	width: 28%;
}
.percentage-29:after {
	width: 29%;
}
.percentage-30:after {
	width: 30%;
}
.percentage-31:after {
	width: 31%;
}
.percentage-32:after {
	width: 32%;
}
.percentage-33:after {
	width: 33%;
}
.percentage-34:after {
	width: 34%;
}
.percentage-35:after {
	width: 35%;
}
.percentage-36:after {
	width: 36%;
}
.percentage-37:after {
	width: 37%;
}
.percentage-38:after {
	width: 38%;
}
.percentage-39:after {
	width: 39%;
}
.percentage-40:after {
	width: 40%;
}
.percentage-41:after {
	width: 41%;
}
.percentage-42:after {
	width: 42%;
}
.percentage-43:after {
	width: 43%;
}
.percentage-44:after {
	width: 44%;
}
.percentage-45:after {
	width: 45%;
}
.percentage-46:after {
	width: 46%;
}
.percentage-47:after {
	width: 47%;
}
.percentage-48:after {
	width: 48%;
}
.percentage-49:after {
	width: 49%;
}
.percentage-50:after {
	width: 50%;
}
.percentage-51:after {
	width: 51%;
}
.percentage-52:after {
	width: 52%;
}
.percentage-53:after {
	width: 53%;
}
.percentage-54:after {
	width: 54%;
}
.percentage-55:after {
	width: 55%;
}
.percentage-56:after {
	width: 56%;
}
.percentage-57:after {
	width: 57%;
}
.percentage-58:after {
	width: 58%;
}
.percentage-59:after {
	width: 59%;
}
.percentage-60:after {
	width: 60%;
}
.percentage-61:after {
	width: 61%;
}
.percentage-62:after {
	width: 62%;
}
.percentage-63:after {
	width: 63%;
}
.percentage-64:after {
	width: 64%;
}
.percentage-65:after {
	width: 65%;
}
.percentage-66:after {
	width: 66%;
}
.percentage-67:after {
	width: 67%;
}
.percentage-68:after {
	width: 68%;
}
.percentage-69:after {
	width: 69%;
}
.percentage-70:after {
	width: 70%;
}
.percentage-71:after {
	width: 71%;
}
.percentage-72:after {
	width: 72%;
}
.percentage-73:after {
	width: 73%;
}
.percentage-74:after {
	width: 74%;
}
.percentage-75:after {
	width: 75%;
}
.percentage-76:after {
	width: 76%;
}
.percentage-77:after {
	width: 77%;
}
.percentage-78:after {
	width: 78%;
}
.percentage-79:after {
	width: 79%;
}
.percentage-80:after {
	width: 80%;
}
.percentage-81:after {
	width: 81%;
}
.percentage-82:after {
	width: 82%;
}
.percentage-83:after {
	width: 83%;
}
.percentage-84:after {
	width: 84%;
}
.percentage-85:after {
	width: 85%;
}
.percentage-86:after {
	width: 86%;
}
.percentage-87:after {
	width: 87%;
}
.percentage-88:after {
	width: 88%;
}
.percentage-89:after {
	width: 89%;
}
.percentage-90:after {
	width: 90%;
}
.percentage-91:after {
	width: 91%;
}
.percentage-92:after {
	width: 92%;
}
.percentage-93:after {
	width: 93%;
}
.percentage-94:after {
	width: 94%;
}
.percentage-95:after {
	width: 95%;
}
.percentage-96:after {
	width: 96%;
}
.percentage-97:after {
	width: 97%;
}
.percentage-98:after {
	width: 98%;
}
.percentage-99:after {
	width: 99%;
}
.percentage-100:after {
	width: 100%;
}

/*#############################################
###############################################
#######              SITEMAP        ###########
###############################################
###############################################*/
#sitemap .hero {
	background-image: url("../images/blog-header.jpg");
}
#sitemap .about {
	background: #0f0f0f;
	padding: 70px 0 70px 0;
}
#sitemap .about .breadcrumb {
	padding: 0;
}
#sitemap .about-detail li::before {
	display: none;
}
#sitemap .about-detail {
	padding: 80px 30px;
}

#sitemap {
	background: url("../images/abstract-gears-bg.png") no-repeat fixed center;
	background-size: cover;
	padding-bottom: 70px;
}

/*#############################################
###############################################
#######              404            ###########
###############################################
###############################################*/

/* start editing from here */

a {
	text-decoration: none;
}
.txt-rt {
	text-align: right;
}
/* text align right */

.txt-lt {
	text-align: left;
}
/* text align left */

.txt-center {
	text-align: center;
}
/* text align center */

.float-rt {
	float: right;
}
/* float right */

.float-lt {
	float: left;
}
/* float left */

.clear {
	clear: both;
}
/* clear float */

.pos-relative {
	position: relative;
}
/* Position Relative */

.pos-absolute {
	position: absolute;
}
/* Position Absolute */

.vertical-base {
	vertical-align: baseline;
}
/* vertical align baseline */

.vertical-top {
	vertical-align: top;
}
/* vertical align top */

.underline {
	padding-bottom: 5px;
	border-bottom: 1px solid #eee;
	margin: 0 0 20px 0;
}
/* Add 5px bottom padding and a underline */

/*end reset*
 */

.content-error p {
	margin: 18px 0 45px 0;
}
.content-error p {
	font-size: 2em;
	color: #666;
	text-align: center;
}
/* .content-error p span,
.logo-error h1 a {
  color: #e54040;
} */
.content-error {
	text-align: center;
	padding: 115px 0 0 0;
}
.content-error a {
	font-weight: 600;
}

.logo-error {
	text-align: center;
	-webkit-box-shadow: 0 8px 6px -6px rgb(97, 97, 97);
	-moz-box-shadow: 0 8px 6px -6px rgb(97, 97, 97);
	box-shadow: 0 8px 6px -6px rgb(97, 97, 97);
}
.logo-error h1 {
	font-size: 2em;
	font-family: "Century Gothic";
	background: #666666;
	/* Old browsers */
	background: -moz-linear-gradient(top, #666666 0%, #666666 100%);
	/* FF3.6+ */
	background: -webkit-gradient(
		linear,
		left top,
		left bottom,
		color-stop(0%, #666666),
		color-stop(100%, #666666)
	);
	/* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top, #666666 0%, #666666 100%);
	/* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top, #666666 0%, #666666 100%);
	/* Opera 11.10+ */
	background: -ms-linear-gradient(top, #666666 0%, #666666 100%);
	/* IE10+ */
	background: linear-gradient(to bottom, #666666 0%, #666666 100%);
	/* W3C */
	filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#666666', endColorstr='#666666', GradientType=0);
	/* IE6-9 */
	padding: 10px 10px 18px 10px;
}
.logo-error h1 a {
	font-size: 1em;
}
.copy-right {
	padding-top: 20px;
}
.copy-right p {
	font-size: 0.9em;
}
.copy-right p a {
	background: none;
	color: #e54040;
	padding: 0 0 5px 0;
	font-size: 0.9em;
}
.copy-right p a:hover {
	color: #666;
}
/*------responive-design--------*/

@media screen and (max-width: 1366px) {
	.content-error {
		padding: 58px 0 0 0;
	}
}
@media screen and (max-width: 1280px) {
	.content-error {
		padding: 58px 0 0 0;
	}
}
@media screen and (max-width: 1024px) {
	.content-error {
		padding: 58px 0 0 0;
	}
	.content-error p {
		font-size: 1.5em;
	}
	.copy-right p {
		font-size: 0.9em;
	}
}
@media screen and (max-width: 640px) {
	.content-error {
		padding: 58px 0 0 0;
	}
	.content-error p {
		font-size: 1.3em;
	}
	.copy-right p {
		font-size: 0.9em;
	}
}
@media screen and (max-width: 460px) {
	.content-error {
		padding: 20px 0 0 0;
		margin: 0 12px;
	}
	.content-error p {
		font-size: 0.9em;
	}
	.copy-right p {
		font-size: 0.8em;
	}
}
@media screen and (max-width: 320px) {
	.content-error {
		padding: 30px 0 0 0;
		margin: 0 12px;
	}
	.content-error a {
		padding: 10px 15px;
		font-size: 0.8em;
	}
	.content-error p {
		margin: 18px 0 22px 0;
	}
}

/* tarteaucitron */
#tarteaucitronAlertBig #tarteaucitronPrivacyUrl,
#tarteaucitronAlertBig #tarteaucitronDisclaimerAlert,
#tarteaucitronAlertBig #tarteaucitronDisclaimerAlert strong {
	font-size: 13px !important;
}
.tarteaucitronAlertBigBtnWrapper {
	display: block !important;
}
#tarteaucitronAlertBig #tarteaucitronCloseAlert,
#tarteaucitronPersonalize {
	font-size: 13px !important;
}
#tarteaucitronRoot #tarteaucitronAlertBig {
	padding: 30px 1px !important;
}
